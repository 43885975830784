/* Spinner.css */
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
    border: 5px solid rgba(255, 0, 0, 0.3); /* Light red background */
    border-top: 5px solid #e22c2a; /* Solid red arc */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  